import React from "react"
import { graphql } from "gatsby"
import Seo from "components/seo"
import Layout from "layouts/layout";
import { ComplianceContainer, ComplianceContent } from './index.module.less'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}: any) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title}
          description={frontmatter.description}
          keywords={frontmatter.keywords}
          saTitle={frontmatter.saTitle} />
      <div className={ComplianceContainer}>
        <div
          className={ComplianceContent}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY年MM月DD日")
        slug
        author
        title
        description
        keywords
        saTitle
      }
    }
  }
`